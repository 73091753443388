











































































import axios from 'axios';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import Chart from '@/components/Chart.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import { chartColors, getChartColorByIndex } from '@/components/util/chartUtil';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';

type FiatCurrency = { name: string; symbol: string };

@Component({
  components: { UiCard, Chart, UiDatePicker2 },
})
export default class AssetSummary extends BaseVue {
  @Prop({ required: true })
  currentFiat!: FiatCurrency;

  svcUrl = this.getReportApiUrl() ?? '';
  isLoading = 0;
  listAll = false;
  collapsedListLength = 20;
  netAssetValue = 0;
  chartData: any = null;
  assetData: any = null;
  selectedDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
  previousPromise: CancelablePromise<any> | null = null;

  readonly columnDefinitions = [
    { key: 'icon' },
    { key: 'asset', label: 'Asset' },
    { key: 'amount', label: 'Amount', align: 'right' },
    { key: 'value', label: 'Value', align: 'right', fiat: true },
  ];

  private readonly chartOptions = {
    aspectRatio: 1,
    maintainAspectRatio: true,
    radius: '100%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (ctx: any) => {
            const formattedValue = ctx.parsed.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            return `${ctx.label}: ${formattedValue}`;
          },
        },
      },
    },
    datasets: {
      doughnut: {
        borderWidth: 1,
        hoverOffset: -3,
      },
    },
    parsing: {
      key: 'value',
    },
  };

  mounted() {
    this.callAssetSummaryEndpoint();
  }

  @Watch('$store.getters.features')
  async onOrgIdUpdated() {
    this.chartData = null;
    this.assetData = null;
    this.netAssetValue = 0;

    this.selectedDate = new Date().toISOString().substring(0, 10);
    this.listAll = false;
    this.callAssetSummaryEndpoint();
  }

  get assetList() {
    return !this.listAll ? this.assetData?.slice(0, this.collapsedListLength) : this.assetData;
  }

  getLabelColor(index: number) {
    return getChartColorByIndex(index);
  }

  isDateDisabled(date: any) {
    return date.getTime() > new Date().getTime();
  }

  public onDateChange(date: string | null) {
    if (date === null || this._isValidDate(date)) {
      this.selectedDate = date ?? '';
      window.pendo?.track('Dashboard - Change asset summary point in time', { date });
    }

    this.callAssetSummaryEndpoint();
  }

  private _isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);
    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  async callAssetSummaryEndpoint() {
    this.isLoading++;

    const orgId = this.$store.state.currentOrg.id;
    const query = { date: this.selectedDate };
    const endpointUrl = getEndpointUrl(
      this.svcUrl,
      ['v2', 'orgs', orgId, 'reports', 'esBalanceReport', 'preview'],
      query
    );

    try {
      this.previousPromise?.cancel();

      const originalPromise = axios.get(endpointUrl, {
        withCredentials: true,
      });
      const cancelablePromise = makeCancelable(originalPromise);
      this.previousPromise = cancelablePromise;
      const response = await cancelablePromise;

      if (response.status === 200) {
        this.assetData =
          response.data.items
            ?.map((record: any) => {
              return {
                amount: this._parseNumbers(record.amount),
                value: this._parseNumbers(record.value),
                asset: record.asset,
              };
            })
            .sort((a: any, b: any) => b.value - a.value) || [];

        this.netAssetValue = this._parseNumbers(response.data.total?.value) || 0;
        this._handleAssetSummaryData();
      } else {
        this.showErrorSnackbar('Failed fetching asset summary data!');
        this.assetData = null;
        this.chartData = null;
      }
    } catch (error) {
      if (error instanceof CanceledError) {
        console.info('The asset symmary promise was canceled');
      } else {
        console.error(error);
        this.showErrorSnackbar('Failed fetching asset summary data!');
        this.assetData = null;
        this.chartData = null;
      }
    } finally {
      this.isLoading--;
    }
  }

  private _parseNumbers(number?: string | number) {
    if (!number) return 0;
    if (typeof number === 'string') return parseFloat(number.replaceAll(',', ''));
    return number;
  }

  private _handleAssetSummaryData() {
    const dataset = {
      label: 'Asset breakdown',
      data: this.assetData?.slice(0, 10) ?? [],
      backgroundColor: chartColors,
    };

    this.chartData = {
      type: 'doughnut',
      data: {
        labels: this.assetData.map((d: any) => d.asset),
        datasets: [dataset],
      },
      options: this.chartOptions,
    };
  }
}
