




















































































































// import gql from 'graphql-tag';
import Component from 'vue-class-component';

// import type { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import Chart from '@/components/Chart.vue';
import Announcements from '@/components/dashboard/accounting/Announcements.vue';
import AssetSummary from '@/components/dashboard/accounting/AssetSummary.vue';
import CashFlow from '@/components/dashboard/accounting/CashFlow.vue';
import CategorizationSummary from '@/components/dashboard/accounting/CategorizationSummary.vue';
import WalletItem from '@/components/dashboard/WalletItem.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';

type FiatCurrency = { name: string; symbol: string };

@Component({
  // apollo: {
  //   wallets: {
  //     query: gql`
  //       query GetWallets($orgId: ID!) {
  //         wallets(orgId: $orgId) {
  //           id
  //           name
  //           description
  //           type
  //           deviceType
  //           address
  //           addresses
  //           path
  //           enabledCoins
  //           bulkSend {
  //             enabled
  //             canEnable
  //           }
  //           balance {
  //             balances {
  //               coin
  //               value
  //               displayValue
  //               fiatValue {
  //                 value
  //                 displayValue
  //                 currency
  //               }
  //             }
  //             totalFiatValue {
  //               displayValue
  //               currency
  //             }
  //           }
  //         }
  //       }
  //     `,
  //     variables() {
  //       return {
  //         orgId: this.$store.state.currentOrg.id,
  //       };
  //     },
  //     loadingKey: 'isLoading',
  //     errorPolicy: 'ignore',
  //   },
  // },
  components: {
    WalletItem,
    Chart,
    UiLoading,
    UiCard,
    CashFlow,
    CategorizationSummary,
    Announcements,
    AssetSummary,
    UiPageHeader,
  },
})
export default class AccountingDashboard extends BaseVue {
  // wallets: Wallet[] = [];
  totalBalance = '0';
  portfolioData: any = null;

  expenseData: any = null;
  revenueData: any = null;

  // blockchains: any = null;

  public get fiat() {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USD';
    return (
      this.$store.getters['fiats/FIATS']?.find(
        (fiat: { name: string; symbol: string }) => fiat.name === baseCurrency
      ) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  // TODO: review if we're keeping anything below

  async mounted() {
    await this.$nextTick();
    this.getExpenseData();
    this.getRevenueData();
  }

  public async getExpenseData() {
    this.expenseData = {
      type: 'doughnut',
      data: {
        labels: ['Rent', 'Wages', 'Utilities', 'Advertising', 'Office Supplies', 'Insurance', 'Depreciation'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [836, 523, 456, 231, 779, 51, 943],
            backgroundColor: ['#3AC08F', '#00A7F8', '#5FC6D0', '#A2D2E8', '#C4E4CF', '#7DBB9A', '#96D1E1'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        radius: 70,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  }

  public async getRevenueData() {
    this.revenueData = {
      type: 'doughnut',
      data: {
        labels: [
          'Sales',
          'Service',
          'Interest Income',
          'Dividend Income',
          'Rental Income',
          'Commission Income',
          'Gain on Sale of Asset',
        ],
        datasets: [
          {
            label: 'My First Dataset',
            data: [312, 609, 887, 666, 79, 912, 445],
            backgroundColor: ['#C4E4CF', '#7DBB9A', '#96D1E1', '#3AC08F', '#00A7F8', '#5FC6D0', '#A2D2E8'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        radius: 70,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  }

  // @Watch('wallets')
  // walletUpdated(val: any) {
  //   this.totalBalance = this.wallets
  //     .reduce(
  //       (tVal, wallet) =>
  //         tVal + (wallet.balance?.balances?.reduce((total: number, cur: any) => total + cur.fiatValue.value, 0) ?? 0),
  //       0
  //     )
  //     .toFixed(2);
  // }
}
